import "./Root.css";
import "./About.css";
import React from "react";

function About({ language }) {
  const content = {
    eng: {
      title: "About Me",
      sections: [
        {
          date: "/22-24",
          title: "MBA Entrepreneurship, Project Management and Innovation in Video Games",
          school: "Gaming Business School",
          location: "Lyon",
        },
        {
          date: "/21-22",
          title: "Bachelor Management & Business, Video Game and Esports Option",
          school: "Gaming Business School",
          location: "Lyon",
          diploma: "Graduated",
        },
        {
          date: "/20-21",
          title: "Bachelor of Computer Development",
          school: "Gaming Tech",
          location: "Lyon",
        },
        {
          date: "/18-20",
          title: "BTS Sio Slam",
          school: "Diderot Education",
          location: "Paris",
          diploma: "Graduated",
        },
        {
          date: "/15-18",
          title: "Bachelor Administration & Management SME",
          school: "ETP Roulleau",
          location: "Paris",
          diploma: "Graduated",
        },
      ],
      skillsTitle: "Skills",
    },
    fr: {
      title: "À propos de moi",
      sections: [
        {
          date: "/22-24",
          title: "MBA Entrepreneuriat, Management de projet et de l'innovation dans le jeu video",
          school: "Gaming Business School",
          location: "Lyon",
        },
        {
          date: "/21-22",
          title: "Bachelor Management & Business, Option jeu video et Esport",
          school: "Gaming Business School",
          location: "Lyon",
          diploma: "Diplôme obtenu",
        },
        {
          date: "/20-21",
          title: "Bachelor Developpeur Informatique",
          school: "Gaming Tech",
          location: "Lyon",
        },
        {
          date: "/18-20",
          title: "BTS Sio Slam",
          school: "Diderot Education",
          location: "Paris",
          diploma: "Diplôme obtenu",
        },
        {
          date: "/15-18",
          title: "Baccalauréat Administration & Gestion PME",
          school: "ETP Roulleau",
          location: "Paris",
          diploma: "Diplôme obtenu",
        },
      ],
      skillsTitle: "Competences",
    },
  };

  const currentContent = content[language];

  return (
    <div className="about-section section">
      <div className="section-title-box">
        <div className="section-title-box-content">
          <img
            className="section-title-img"
            src="./images/dots-3-pack.png"
            alt=""
          />
          <h2 className="section-title-text">{currentContent.title}</h2>
        </div>
        <hr className="section-footer-box-hr" />
        <hr className="section-title-box-hr" />
      </div>
      <div className="about-section-box">
        <img className="about-img" src="./images/about-img.png" alt="" />

        <div className="about-section-right">
          <hr className="about-hr" />

          <div className="about-container">
            {currentContent.sections.map((section, index) => (
              <div key={index} className="about-box">
                <p className="about-box-date">{section.date}</p>
                <img
                  src="./images/dots-3-line.png"
                  className="about-box-img"
                  alt=""
                />
                <div className="about-box-content-line">
                  <div className="about-box-content">
                  <h3 className="about-box-content-title">{section.title}</h3>
                  <div className="about-box-infos">
                    <hr className="about-box-infos-hr" />
                    <p className="about-box-infos-bold-text">
                      {section.school},
                    </p>
                    <p className="about-box-infos-light-text">
                      {section.location}
                    </p>
                  </div>
                </div>
                {section.diploma && (
                  <div className="about-box-infos-diplome-box">
                    <i className="bi bi-mortarboard-fill"></i>
                    <p className="about-box-infos-diplome-text">
                      {section.diploma}
                    </p>
                  </div>
                )}
                </div>
                
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section-title-box">
        <div className="section-title-box-content">
          <h2 className="section-title-text">{currentContent.skillsTitle}</h2>
        </div>
        <hr className="section-footer-box-hr" />
        <hr className="section-footer-title-box-hr" />
        <img
          className="section-footer-img"
          src="./images/dots-3-pack.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default About;
