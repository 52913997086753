import "./Root.css";
import "./Hero.css";
import React from "react";

function Hero() {
  return (
    <div className="hero-section section">
      <div className="hero-section-header">
        <h1 className="hero-section-header-title">etienne dubois</h1>
        <div className="hero-section-header-bottom">
          <hr className="hero-section-header-bottom-hr"></hr>
          <p className="hero-section-header-bottom-text">Product Owner</p>
        </div>
      </div>
      <img className="hero-img" src="./images/hero-img.png"/>
    </div>
  );
}

export default Hero;
