import "./Root.css";
import "./Experiences.css";
import React, { useState } from "react";

function Experiences({ language }) {
  const [activeExperience, setActiveExperience] = useState(0);

  const content = {
    eng: {
      title: "Experiences",
      experiences: [
        {
          title: "Product Owner",
          date: "/23-24",
          company: "Compagnie des Alpes",
          description: "Management of the backlog and prioritization of tasks. Operational support for ski resorts.",
          logo: "./images/cda-logo.png",
          missions: [
            "Management of the backlog and prioritization of tasks.",
            "Operational support for ski resorts.",
            "Coordination of QA and UAT tests.",
            "Continuous communication with local resort teams.",
          ],
          skills: [
            "Agile project management.",
            "Excellent communication skills.",
            "Knowledge of QA and UAT testing processes.",
          ],
        },
        {
          title: "Product Owner",
          date: "/22-23",
          company: "BeeVora",
          description: "Drafting specifications and technical requirements. Functional testing and backlog management.",
          logo: "./images/beevora-logo.png",
          missions: [
            "Drafting specifications for designers and developers.",
            "Collaboration and follow-up with design teams.",
            "Functional and regression testing.",
            "Management and prioritization of team backlogs.",
          ],
          skills: [
            "Communication and coordination skills.",
            "Drafting specifications and technical requirements.",
            "Knowledge of UX/UI processes and web development.",
          ],
        },
        {
          title: "Web Developer",
          date: "/22",
          company: "Penh House",
          description: "Website creation using WordPress and user training on Excel.",
          logo: "./images/penh-logo.png",
          missions: [
            "Website creation using WordPress.",
            "User training on Excel.",
            "Development of budget forecasting spreadsheets.",
          ],
          skills: [
            "Mastery of WordPress.",
            "Training and technical support skills.",
            "Expertise in budget management and forecasting.",
          ],
        },
        {
          title: "Producer",
          date: "/21-22",
          company: "The Last Petal",
          description: "Management of artistic and development teams. Planning and monitoring production.",
          logo: "./images/fl-logo.png",
          missions: [
            "Management of artistic and development teams.",
            "Planning and monitoring production.",
            "Coordination of resources and deadlines.",
          ],
          skills: [
            "Agile project management.",
            "Game development skills.",
            "Advanced use of Microsoft Office.",
          ],
        },
        {
          title: "Game Developer",
          date: "/20-21",
          company: "The Last Petal",
          description: "Development of the game's foundations. Collaboration with creative teams.",
          logo: "./images/fl-logo.png",
          missions: [
            "Development of the game's foundations.",
            "Collaboration with creative teams.",
            "Participation in gameplay design.",
          ],
          skills: [
            "Game development.",
            "Project management.",
            "Video game design.",
          ],
        },
      ],
      missionsTitle: "Missions",
      skillsTitle: "Skills",
    },
    fr: {
      title: "Experiences",
      experiences: [
        {
          title: "Product Owner",
          date: "/23-24",
          company: "Compagnie des Alpes",
          description: "Gestion du backlog et priorisation des tâches. Support opérationnel auprès des stations de ski.",
          logo: "./images/cda-logo.png",
          missions: [
            "Gestion du backlog et priorisation des tâches.",
            "Support opérationnel auprès des stations de ski.",
            "Coordination des tests QA et UAT.",
            "Communication continue avec les équipes locales des stations.",
          ],
          skills: [
            "Gestion de projet agile.",
            "Excellentes compétences en communication.",
            "Connaissance des processus de tests QA et UAT.",
          ],
        },
        {
          title: "Product Owner",
          date: "/22-23",
          company: "BeeVora",
          description: "Élaboration de cahiers des charges et spécifications techniques. Tests fonctionnels et gestion du backlog.",
          logo: "./images/beevora-logo.png",
          missions: [
            "Élaboration du cahier des charges pour designers et développeurs.",
            "Collaboration et suivi avec les équipes de design.",
            "Tests fonctionnels et de non-régression.",
            "Gestion et priorisation du backlog des équipes.",
          ],
          skills: [
            "Compétences en communication et coordination.",
            "Rédaction de cahiers des charges et spécifications techniques.",
            "Connaissance des processus UX/UI et développement web.",
          ],
        },
        {
          title: "Developpeur Web",
          date: "/22",
          company: "Penh House",
          description: "Création de sites web sous WordPress et formation des utilisateurs sur Excel.",
          logo: "./images/penh-logo.png",
          missions: [
            "Création de sites web sous WordPress.",
            "Formation des utilisateurs sur Excel.",
            "Élaboration de tableurs de prévision budgétaire.",
          ],
          skills: [
            "Maîtrise de WordPress.",
            "Compétences en formation et support technique.",
            "Expertise en gestion et prévision budgétaire.",
          ],
        },
        {
          title: "Producer",
          date: "/21-22",
          company: "The Last Petal",
          description: "Gestion des équipes artistiques et de développement. Planification et suivi de la production.",
          logo: "./images/cda-logo.png",
          missions: [
            "Gestion des équipes artistiques et de développement.",
            "Planification et suivi de la production.",
            "Coordination des ressources et des délais.",
          ],
          skills: [
            "Gestion de projet agile.",
            "Compétences en développement de jeux.",
            "Utilisation avancée de Microsoft Office.",
          ],
        },
        {
          title: "Developpeur Jeux Vidéo",
          date: "/20-21",
          company: "The Last Petal",
          description: "Développement des bases du jeu. Collaboration avec les équipes créatives.",
          logo: "./images/cda-logo.png",
          missions: [
            "Développement des bases du jeu.",
            "Collaboration avec les équipes créatives.",
            "Participation à la conception du gameplay.",
          ],
          skills: [
            "Développement de jeux.",
            "Gestion de projet.",
            "Conception de jeux vidéo.",
          ],
        },
      ],
      missionsTitle: "Missions",
      skillsTitle: "Competences",
    },
  };

  const currentContent = content[language];

  return (
    <div className="experiences-section section">
      <div className="section-title-box">
        <div className="section-title-box-content">
          <img
            className="section-title-img"
            src="./images/dots-3-pack.png"
            alt="Dots"
          />
          <h2 className="section-title-text">{currentContent.title}</h2>
        </div>
        <hr className="section-footer-box-hr"></hr>
        <hr className="section-title-box-hr"></hr>
      </div>
      <div className="experiences-section-box">
        <div className="experiences-section-left">
          {currentContent.experiences.map((experience, index) => (
            <React.Fragment key={index}>
              <div
                className={`experiences-section-left-box ${
                  activeExperience === index ? "active" : ""
                }`}
                onClick={() => setActiveExperience(index)}
              >
                <div className="experiences-section-left-box-container">
                  <div className="experiences-section-left-box-top">
                    <h3 className="experiences-section-left-box-top-title">
                      {experience.title}
                    </h3>
                    <p className="experiences-section-left-box-top-date">
                      {experience.date}
                    </p>
                  </div>
                  <div className="experiences-section-left-box-bot">
                    <hr className="experiences-section-left-box-bot-hr"></hr>
                    <p className="experiences-section-left-box-bot-text">
                      {experience.company}
                    </p>
                  </div>
                </div>
                {activeExperience === index && (
                  <i className="bi bi-chevron-compact-right"></i>
                )}
              </div>
              {activeExperience === index && (
                <div className="experience-detail-mobile">
                  <div className="experiences-detail-top">
                    <p className="experiences-detail-top-text">
                      {experience.description}
                    </p>
                    {experience.logo && (
                      <img
                        className="experiences-detail-top-img"
                        src={experience.logo}
                        alt="Detail"
                      />
                    )}
                  </div>
                  <div className="experiences-detail-list">
                    <h4 className="experiences-detail-list-title">{currentContent.missionsTitle}</h4>
                    <ul className="experiences-detail-list-box">
                      {experience.missions.map((mission, i) => (
                        <li key={i} className="experiences-detail-list-item">
                          &#x2022; {mission}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="experiences-detail-list">
                    <h4 className="experiences-detail-list-title">{currentContent.skillsTitle}</h4>
                    <ul className="experiences-detail-list-box">
                      {experience.skills.map((skill, i) => (
                        <li key={i} className="experiences-detail-list-item">
                          &#x2022; {skill}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <hr className="experiences-section-hr"></hr>

        <div className="experiences-section-right">
          {currentContent.experiences.map((experience, index) => (
            activeExperience === index && (
              <div key={index} className="experience-detail">
                <div className="experiences-detail-top">
                  <p className="experiences-detail-top-text">
                    {experience.description}
                  </p>
                  {experience.logo && (
                    <img
                      className="experiences-detail-top-img"
                      src={experience.logo}
                      alt="Detail"
                    />
                  )}
                </div>
                <div className="experiences-detail-list">
                  <h4 className="experiences-detail-list-title">{currentContent.missionsTitle}</h4>
                  <ul className="experiences-detail-list-box">
                    {experience.missions.map((mission, i) => (
                      <li key={i} className="experiences-detail-list-item">
                        &#x2022; {mission}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="experiences-detail-list">
                  <h4 className="experiences-detail-list-title">{currentContent.skillsTitle}</h4>
                  <ul className="experiences-detail-list-box">
                    {experience.skills.map((skill, i) => (
                      <li key={i} className="experiences-detail-list-item">
                        &#x2022; {skill}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          ))}
        </div>
      </div>
      <div className="section-title-box">
        <div className="section-title-box-content">
          <h2 className="section-title-text">{currentContent.skillsTitle}</h2>
        </div>
        <hr className="section-footer-box-hr"></hr>
        <hr className="section-footer-title-box-hr"></hr>
        <img
          className="section-footer-img"
          src="./images/dots-3-pack.png"
          alt="Dots"
        />
      </div>
    </div>
  );
}

export default Experiences;
