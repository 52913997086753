import "./Root.css";
import "./Skills.css";
import React from "react";

function Skills({ language }) {
  const content = {
    eng: {
      title: "My Skills",
      sections: [
        {
          title: "Softwares",
          logos: ["./images/logo-1.png", "./images/logo-2.png", "./images/logo-3.png", "./images/logo-4.png", "./images/logo-5.png", "./images/logo-6.png", "./images/logo-7.png", "./images/logo-8.png", "./images/logo-9.png", "./images/logo-10.png"],
        },
        {
          title: "Programming Languages",
          logos: ["./images/code-1.png", "./images/code-2.png", "./images/code-3.png", "./images/code-4.png", "./images/code-5.png", "./images/code-6.png", "./images/code-7.png", "./images/code-8.png", "./images/code-9.png"],
        },
        {
          title: "Languages",
          logos: [
            { src: "./images/langue-FR.png", text: "Native" },
            { src: "./images/langue-ENG.png", text: "B2" },
            { src: "./images/langue-ESP.png", text: "A2" },
            { src: "./images/langue-JP.png", text: "A1" },
          ],
        },
        {
          title: "Project Management",
          methods: ["AGILE Method", "Specifications", "Budgeting", "Mockup / Prototyping", "Communication Plan", "Team Management"],
        },
      ],
      experiencesTitle: "Experiences",
    },
    fr: {
      title: "Mes Competences",
      sections: [
        {
          title: "Logiciels",
          logos: ["./images/logo-1.png", "./images/logo-2.png", "./images/logo-3.png", "./images/logo-4.png", "./images/logo-5.png", "./images/logo-6.png", "./images/logo-7.png"],
        },
        {
          title: "Langages Informatiques",
          logos: ["./images/code-1.png", "./images/code-2.png", "./images/code-3.png", "./images/code-4.png", "./images/code-5.png", "./images/code-6.png", "./images/code-7.png", "./images/code-8.png", "./images/code-9.png"],
        },
        {
          title: "Langues",
          logos: [
            { src: "./images/langue-FR.png", text: "Natif" },
            { src: "./images/langue-ENG.png", text: "B2" },
            { src: "./images/langue-ESP.png", text: "A2" },
            { src: "./images/langue-JP.png", text: "A1" },
          ],
        },
        {
          title: "Gestion de projet",
          methods: ["Méthode Agile", "Cahier des charges", "Budgétisation", "Maquette/Prototypage", "Plan de communication", "Gestion des équipes"],
        },
      ],
      experiencesTitle: "Experiences",
    },
  };

  const currentContent = content[language];

  return (
    <div className="skills-section section">
      <div className="section-title-box">
        <div className="section-title-box-content">
          <img className="section-title-img" src="./images/dots-3-pack.png" alt="" />
          <h2 className="section-title-text">{currentContent.title}</h2>
        </div>
        <hr className="section-footer-box-hr" />
        <hr className="section-title-box-hr" />
      </div>
      <div className="skills-section-box">
        <img className="skills-img" src="./images/about-img.png" alt="" />
        <div className="skills-section-right">
          <hr className="skills-hr" />
          <div className="skills-container">
            {currentContent.sections.map((section, index) => (
              <div key={index} className="skills-box">
                <img src="./images/dots-3-line.png" className="skills-box-img" alt="" />
                <div className="skills-box-content">
                  <h3 className="skills-box-content-title">{section.title}</h3>
                  <div className="skills-box-logos">
                    {section.logos ? (
                      section.logos.map((logo, i) => (
                        <React.Fragment key={i}>
                          {logo.src ? (
                            <div className="skills-box-logos-langue">
                              <img src={logo.src} className="skills-box-logo" alt="" />
                              <p className="skills-box-logos-langue-text">{logo.text}</p>
                            </div>
                          ) : (
                            <img src={logo} className="skills-box-logo" alt="" />
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      section.methods.map((method, i) => (
                        <React.Fragment key={i}>
                          <p className="skills-box-logos-buble">{method}</p>
                          {i < section.methods.length - 1 && <i className="bi bi-dot"></i>}
                        </React.Fragment>
                      ))
                    )}
                  </div>
                </div>
              </div>
            ))}
            <hr className="hr-mobile" />
          </div>
        </div>
      </div>
      <div className="section-title-box">
        <div className="section-title-box-content">
          <h2 className="section-title-text">{currentContent.experiencesTitle}</h2>
        </div>
        <hr className="section-footer-box-hr" />
        <hr className="section-footer-title-box-hr" />
        <img className="section-footer-img" src="./images/dots-3-pack.png" alt="" />
      </div>
    </div>
  );
}

export default Skills;
