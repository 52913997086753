import "./Root.css";
import "./Contact.css";
import React, { useState, useEffect } from "react";
import { db } from '../firebaseconfig';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

function Contact({ language }) {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timer on unmount or message change
    }
  }, [errorMessage, successMessage]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, "contacts"), {
        name: form.name,
        email: form.email,
        message: form.message,
        timestamp: serverTimestamp()
      });
      setSuccessMessage(language === 'eng' ? "Message sent successfully!" : "Message envoyé avec succès !");
      setErrorMessage('');
      setForm({ name: '', email: '', message: '' });
    } catch (error) {
      console.error("Error adding document: ", error);
      setErrorMessage(language === 'eng' ? "There was an error sending your message. Please try again." : "Une erreur est survenue lors de l'envoi de votre message. Veuillez réessayer.");
      setSuccessMessage('');
    }
  };

  const content = {
    eng: {
      title: "Contact Me",
      nameLabel: "Name",
      namePlaceholder: "John Doe",
      emailLabel: "Email Address",
      emailPlaceholder: "yourname@email.com",
      messageLabel: "Your Message",
      sendButton: "Send",
      footer: "2024© Etienne Dubois",
    },
    fr: {
      title: "Contactez Moi",
      nameLabel: "Nom & Prénom",
      namePlaceholder: "Ade Tiger",
      emailLabel: "Adresse mail",
      emailPlaceholder: "votrenom@email.com",
      messageLabel: "Votre message",
      sendButton: "Envoyer",
      footer: "2024© Etienne Dubois",
    },
  };

  const currentContent = content[language];

  return (
    <div className="contact-section section">
      <div className="section-title-box">
        <div className="section-title-box-content">
          <img
            className="section-title-img"
            src="./images/dots-3-pack.png"
            alt="Decoration"
          />
          <h2 className="section-title-text">{currentContent.title}</h2>
        </div>
        <hr className="section-footer-box-hr"></hr>
        <hr className="section-title-box-hr"></hr>
      </div>
      <form className="contact-form-container" onSubmit={handleSubmit}>
        <div className="contact-form-row">
          <div className="contact-form-item">
            <h3 className="contact-form-label">
              {currentContent.nameLabel} <span className="contact-form-label-star">*</span>
            </h3>
            <input
              className="contact-form-input"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder={currentContent.namePlaceholder}
              required
            />
          </div>
          <div className="contact-form-item">
            <h3 className="contact-form-label">
              {currentContent.emailLabel} <span className="contact-form-label-star">*</span>
            </h3>
            <input
              className="contact-form-input"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              placeholder={currentContent.emailPlaceholder}
              required
            />
          </div>
        </div>
        <div className="contact-form-row">
          <div className="contact-form-item">
            <h3 className="contact-form-label">
              {currentContent.messageLabel} <span className="contact-form-label-star">*</span>
            </h3>
            <textarea
              className="contact-form-textarea"
              name="message"
              value={form.message}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <button className="contact-form-btn" type="submit">{currentContent.sendButton}</button>
      </form>
      <div className="section-title-box">
        <hr className="section-title-box-hr"></hr>
        <hr className="section-footer-box-hr"></hr>
        <p className="section-footer-box-text">{currentContent.footer}</p>
      </div>
    </div>
  );
}

export default Contact;
