import "./Root.css";
import "./BtnNav.css";
import React from "react";

function BtnNav({ currentComponent, changeComponent }) {
  return (
    <div className={`btn-nav ${currentComponent === 0 ? "first-slide" : ""}`}>
      {currentComponent === 0 ? (
        <>
          <button 
            onClick={() => changeComponent(currentComponent + 1)} 
            className="fade-button"
          >
            <i className="bi bi-chevron-down"></i>
          </button>
          <img 
            src="./images/btn-down.png" 
            alt="Next Slide" 
            onClick={() => changeComponent(currentComponent + 1)}
            className="next-slide-image"
          />
        </>
      ) : (
        <>
          <button 
            onClick={() => changeComponent(currentComponent - 1)} 
            disabled={currentComponent === 0}
            className="fade-button"
          >
            <i className="bi bi-chevron-up"></i>
          </button>
          <button 
            onClick={() => changeComponent(currentComponent + 1)} 
            disabled={currentComponent === 4}
            style={{ opacity: currentComponent === 4 ? 0 : 1 }}
            className="fade-button"
          >
            <i className="bi bi-chevron-down"></i>
          </button>
        </>
      )}
    </div>
  );
}

export default BtnNav;
