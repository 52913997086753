import { useState, useEffect } from 'react';
import BtnNav from './components/BtnNav';
import About from './components/About';
import Contact from './components/Contact';
import Experiences from './components/Experiences';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import './components/Root.css';
import Skills from './components/Skills';
import FakeScrollbar from './components/FakeScrollbar';

function App() {
  const [currentComponent, setCurrentComponent] = useState(0);
  const [language, setLanguage] = useState('eng'); // default language is English
  const [scrollAccumulator, setScrollAccumulator] = useState(0);
  const scrollThreshold = 300; // Adjust this value to control the scroll distance required to change component
  const components = [
    <Hero key="hero" language={language} />,
    <About key="about" language={language} />,
    <Skills key="skills" language={language} />,
    <Experiences key="experiences" language={language} />,
    <Contact key="contact" language={language} />
  ];

  const changeComponent = (index) => {
    if (index >= 0 && index < components.length) {
      setCurrentComponent(index);
      window.scrollTo(0, 0); // Reset scroll position to the top
    }
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  const handleScroll = (event) => {
    setScrollAccumulator(prev => prev + event.deltaY);
  };

  let touchStartY = 0;

  const handleTouchStart = (event) => {
    touchStartY = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    const touchEndY = event.touches[0].clientY;
    const deltaY = touchStartY - touchEndY;
    setScrollAccumulator(prev => prev + deltaY);
    touchStartY = touchEndY; // Update start point for the next move event
  };

  useEffect(() => {
    if (scrollAccumulator >= scrollThreshold && currentComponent < components.length - 1) {
      changeComponent(currentComponent + 1);
      setScrollAccumulator(0); // Reset accumulator after changing component
    } else if (scrollAccumulator <= -scrollThreshold && currentComponent > 0) {
      changeComponent(currentComponent - 1);
      setScrollAccumulator(0); // Reset accumulator after changing component
    }
  }, [scrollAccumulator, currentComponent]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return (
    <>
      <Navbar changeComponent={changeComponent} changeLanguage={changeLanguage} language={language} />
      <div className="component-container" style={{ height: '100vh', overflow: 'hidden' }}>
        {components[currentComponent]}
      </div>
      <BtnNav currentComponent={currentComponent} changeComponent={changeComponent} />
      <FakeScrollbar currentComponent={currentComponent} totalComponents={components.length} />
    </>
  );
}

export default App;
