import React from "react";
import "./FakeScrollbar.css";

function FakeScrollbar({ currentComponent, totalComponents }) {
  const scrollBarHeight = 100 / totalComponents;
  const topPosition = `${currentComponent * scrollBarHeight}%`;

  return (
    <div className="fake-container">
      <div className="fake-scrollbar">
        <div
          className="moving-scrollbar"
          style={{ top: topPosition, height: `${scrollBarHeight}%` }}
        ></div>
      </div>
      <div className="fake-links">
      <a className="fake-links-item" href="https://www.linkedin.com/in/etienne-dubois-13663a152/" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a>
      <a className="fake-links-item" href="tel:+33619816160" target="_blank" rel="noopener noreferrer"><i className="bi bi-telephone-fill"></i></a>
      <a className="fake-links-item" href="mailto:etiennedub146@gmail.com" target="_blank" rel="noopener noreferrer"><i className="bi bi-envelope-fill"></i></a>      </div>
    </div>
  );
};

export default FakeScrollbar;
