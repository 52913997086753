import React, { useState, useEffect } from 'react';
import './Navbar.css';
import './Root.css';

function Navbar({ changeComponent, changeLanguage, language }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const content = {
    eng: {
      about: "About",
      experiences: "Experiences",
      skills: "Skills",
      contact: "Contact",
    },
    fr: {
      about: "A propos",
      experiences: "Experiences",
      skills: "Competences",
      contact: "Contact",
    },
  };

  const currentContent = content[language];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (index) => {
    changeComponent(index);
    setIsMenuOpen(false);
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  };

  useEffect(() => {
    const navbarPadding = document.querySelector('.navbar__padding');
    if (isMenuOpen) {
      navbarPadding.style.backgroundColor = 'var(--menu-bg)';
    } else {
      navbarPadding.style.backgroundColor = '';
    }
  }, [isMenuOpen]);

  return (
    <nav className="navbar__padding">
      <div className="navbar__pc">
        <a href="#" className="nav__logo" onClick={() => handleLinkClick(0)}>
          <img src="./images/logo-e-dubois.png" alt="" />
        </a>
        <div className="navbar__right">
          <ul className="nav__links nav-bg">
            <li>
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(1)}>
                {currentContent.about}
              </a>
            </li>
            <li className="coming-soon">
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(2)}>
                {currentContent.skills}
              </a>
            </li>
            <li className="coming-soon">
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(3)}>
                {currentContent.experiences}
              </a>
            </li>

            <li className="coming-soon">
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(4)}>
                {currentContent.contact}
              </a>
            </li>
          </ul>

          <div className="nav__links-languages-box">
            <a className="nav__links-btn" onClick={() => handleLanguageChange('fr')}>
              fr
            </a>
            <hr className="nav__links-languages-box-hr"></hr>
            <a className="nav__links-btn" onClick={() => handleLanguageChange('eng')}>
              eng
            </a>
          </div>
        </div>
      </div>

      <div className="navbar__mobile">
        <div className="navbar__mobile-head">
          <a href="#" className="nav__logo" onClick={() => handleLinkClick(0)}>
            <img src="./images/logo-e-dubois.png" alt="" />
          </a>
          <div
            className="navbar__mobile-menuIcon"
            id="menuIcon"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <i className="bi bi-x"></i> : <i className="bi bi-list"></i>}
          </div>
        </div>
        <div
          className={`navbar__mobile-content ${isMenuOpen ? "animate__fadeInLeft" : ""}`}
          style={{ display: isMenuOpen ? "block" : "none", left: isMenuOpen ? '-11%' : '-150%' }}
        >
          <ul className="nav__links">
            <li>
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(1)}>
                {currentContent.about}
              </a>
            </li>
            <li className="coming-soon">
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(2)}>
                {currentContent.skills}
              </a>
            </li>
            <li className="coming-soon">
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(3)}>
                {currentContent.experiences}
              </a>
            </li>
            <li className="coming-soon">
              <a href="#" className="nav__links-btn" onClick={() => handleLinkClick(4)}>
                {currentContent.contact}
              </a>
            </li>
            <li>
              <div className="nav__links-languages-box">
                <a className="nav__links-btn" onClick={() => handleLanguageChange('fr')}>
                  fr
                </a>
                <hr className="nav__links-languages-box-hr"></hr>
                <a className="nav__links-btn" onClick={() => handleLanguageChange('eng')}>
                  eng
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
